import { App } from '@/App';
import ModalStackManager from '@/components/Modal/ModalStackManager';
import '@/index.css';
import '@/polyfills';
import reportWebVitals from '@/reportWebVitals';
import { MantineProvider } from '@mantine/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import config from 'devextreme/core/config';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import GlobalStyle from './styles/Global';

const licenseKey = process.env.REACT_APP_DEVEXPRESS_LICENSE_KEY;

config({ licenseKey });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Create a client
const queryClient = new QueryClient()

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <MantineProvider withGlobalStyles>
        <ModalStackManager>
          <GlobalStyle />
          {/* TODO: fallback 상태에서 표시할 Loading Bar 추가 필요 - 2024.09.10 by rebi13 */}
          <Suspense fallback={<div>Loading...</div>}>
            <App />
          </Suspense>
        </ModalStackManager>
      </MantineProvider>
    </QueryClientProvider>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
