import { lazy } from "react";

// 샘플 페이지 컴포넌트로 Dashboard와 Login이 있습니다.
export const Login = lazy(() => import("@/pages/Login").then((module) => ({ default: module.Login })));

// 페이지 컴포넌트를 named export로 비동기적으로 import합니다.
// 모니터링 > 개별 모니터링
const EnergyConsumptionProduction = lazy(() =>
    import("@/pages/Monitoring/Dashboard/EnergyConsumptionProduction").then((module) => ({ default: module.EnergyConsumptionProduction }))
);
const RealTimeEnergyCost = lazy(() =>
    import("@/pages/Monitoring/Dashboard/RealTimeEnergyCost").then((module) => ({ default: module.RealTimeEnergyCost }))
);
const CarbonReduction = lazy(() =>
    import("@/pages/Monitoring/Dashboard/CarbonReduction").then((module) => ({ default: module.CarbonReduction }))
);
const KPIIndicators = lazy(() =>
    import("@/pages/Monitoring/Dashboard/KPIIndicators").then((module) => ({ default: module.KPIIndicators }))
);
const IntegratedMonitoring = lazy(() =>
    import("@/pages/Monitoring/Dashboard/IntegratedMonitoring").then((module) => ({ default: module.IntegratedMonitoring }))
);
const MainEquipmentMonitoring = lazy(() =>
    import("@/pages/Monitoring/Dashboard/MainEquipmentMonitoring").then((module) => ({ default: module.MainEquipmentMonitoring }))
);

// 분석 > 개별 업체 분석
const DailyEnergyConsumption = lazy(() =>
    import("@/pages/Analysis/VendorAnalysis/DailyEnergyConsumption").then((module) => ({ default: module.DailyEnergyConsumption }))
);
const MonthlyEnergyConsumption = lazy(() =>
    import("@/pages/Analysis/VendorAnalysis/MonthlyEnergyConsumption").then((module) => ({ default: module.MonthlyEnergyConsumption }))
);
const YearlyEnergyConsumption = lazy(() =>
    import("@/pages/Analysis/VendorAnalysis/YearlyEnergyConsumption").then((module) => ({ default: module.YearlyEnergyConsumption }))
);
const DailyPowerPeak = lazy(() =>
    import("@/pages/Analysis/VendorAnalysis/DailyPowerPeak").then((module) => ({ default: module.DailyPowerPeak }))
);
const MonthlyPowerPeak = lazy(() =>
    import("@/pages/Analysis/VendorAnalysis/MonthlyPowerPeak").then((module) => ({ default: module.MonthlyPowerPeak }))
);
const EnergyConsumptionProductionReport = lazy(() =>
    import("@/pages/Analysis/VendorAnalysis/EnergyConsumptionProductionReport").then((module) => ({ default: module.EnergyConsumptionProductionReport }))
);
const MonthlyBilling = lazy(() =>
    import("@/pages/Analysis/VendorAnalysis/MonthlyBilling").then((module) => ({ default: module.MonthlyBilling }))
);

// 분석 > 시뮬레이션
const TariffComparison = lazy(() =>
    import("@/pages/Analysis/Simulation/TariffComparison").then((module) => ({ default: module.TariffComparison }))
);
const HourlyPowerShift = lazy(() =>
    import("@/pages/Analysis/Simulation/HourlyPowerShift").then((module) => ({ default: module.HourlyPowerShift }))
);
const DailySolarProduction = lazy(() =>
    import("@/pages/Analysis/Simulation/DailySolarProduction").then((module) => ({ default: module.DailySolarProduction }))
);
const ESSApplication = lazy(() =>
    import("@/pages/Analysis/Simulation/ESSApplication").then((module) => ({ default: module.ESSApplication }))
);

// 에너지경영 > 에너지기획
const EnergyPolicyManagement = lazy(() =>
    import("@/pages/EnergyManagement/EnergyPlanning/EnergyPolicyManagement").then((module) => ({ default: module.EnergyPolicyManagement }))
);
const EnergyRegulationManagement = lazy(() =>
    import("@/pages/EnergyManagement/EnergyPlanning/EnergyRegulationManagement").then((module) => ({ default: module.EnergyRegulationManagement }))
);
const BaselineManagement = lazy(() =>
    import("@/pages/EnergyManagement/EnergyPlanning/BaselineManagement").then((module) => ({ default: module.BaselineManagement }))
);
const EnergyTargetManagement = lazy(() =>
    import("@/pages/EnergyManagement/EnergyPlanning/EnergyTargetManagement").then((module) => ({ default: module.EnergyTargetManagement }))
);
const DetailedTargetManagement = lazy(() =>
    import("@/pages/EnergyManagement/EnergyPlanning/DetailedTargetManagement").then((module) => ({ default: module.DetailedTargetManagement }))
);
const EnPIManagement = lazy(() =>
    import("@/pages/EnergyManagement/EnergyPlanning/EnPIManagement").then((module) => ({ default: module.EnPIManagement }))
);
const EnergyImprovementProjectManagement = lazy(() =>
    import("@/pages/EnergyManagement/EnergyPlanning/EnergyImprovementProjectManagement").then((module) => ({ default: module.EnergyImprovementProjectManagement }))
);

// 에너지경영 > 실행 및 운영
const EnergyTargetReductionPerformance = lazy(() =>
    import("@/pages/EnergyManagement/ImplementationAndOperation/EnergyTargetReductionPerformance").then((module) => ({ default: module.EnergyTargetReductionPerformance }))
);
const DetailedTargetReductionPerformance = lazy(() =>
    import("@/pages/EnergyManagement/ImplementationAndOperation/DetailedTargetReductionPerformance").then((module) => ({ default: module.DetailedTargetReductionPerformance }))
);
const EnergyProjectPerformance = lazy(() =>
    import("@/pages/EnergyManagement/ImplementationAndOperation/EnergyProjectPerformance").then((module) => ({ default: module.EnergyProjectPerformance }))
);

// 에너지경영 > 점검 및 조치
const BaselineInternalAuditManagement = lazy(() =>
    import("@/pages/EnergyManagement/InspectionAndAction/BaselineInternalAuditManagement").then((module) => ({ default: module.BaselineInternalAuditManagement }))
);

// 에너지경영 > RE100
const RE100Achievement = lazy(() =>
    import("@/pages/EnergyManagement/RE100/RE100Achievement").then((module) => ({ default: module.RE100Achievement }))
);


export const RoutePaths = [
    { path: "/", element: <Login /> },
    { path: "/login", element: <Login /> },
    { path: "/energy-consumption-production", element: <EnergyConsumptionProduction /> },
    { path: "/real-time-energy-cost", element: <RealTimeEnergyCost /> },
    { path: "/carbon-reduction", element: <CarbonReduction /> },
    { path: "/kpi-indicators", element: <KPIIndicators /> },
    { path: "/integrated-monitoring", element: <IntegratedMonitoring /> },
    { path: "/main-equipment-monitoring", element: <MainEquipmentMonitoring /> },
    { path: "/daily-energy-consumption", element: <DailyEnergyConsumption /> },
    { path: "/monthly-energy-consumption", element: <MonthlyEnergyConsumption /> },
    { path: "/yearly-energy-consumption", element: <YearlyEnergyConsumption /> },
    { path: "/daily-power-peak", element: <DailyPowerPeak /> },
    { path: "/monthly-power-peak", element: <MonthlyPowerPeak /> },
    { path: "/energy-consumption-production-report", element: <EnergyConsumptionProductionReport /> },
    { path: "/monthly-billing", element: <MonthlyBilling /> },
    { path: "/tariff-comparison", element: <TariffComparison /> },
    { path: "/hourly-power-shift", element: <HourlyPowerShift /> },
    { path: "/daily-solar-production", element: <DailySolarProduction /> },
    { path: "/ess-application", element: <ESSApplication /> },
    { path: "/energy-policy-management", element: <EnergyPolicyManagement /> },
    { path: "/energy-regulation-management", element: <EnergyRegulationManagement /> },
    { path: "/baseline-management", element: <BaselineManagement /> },
    { path: "/energy-target-management", element: <EnergyTargetManagement /> },
    { path: "/detailed-target-management", element: <DetailedTargetManagement /> },
    { path: "/enpi-management", element: <EnPIManagement /> },
    { path: "/energy-improvement-project-management", element: <EnergyImprovementProjectManagement /> },
    { path: "/energy-target-reduction-performance", element: <EnergyTargetReductionPerformance /> },
    { path: "/detailed-target-reduction-performance", element: <DetailedTargetReductionPerformance /> },
    { path: "/energy-project-performance", element: <EnergyProjectPerformance /> },
    { path: "/baseline-internal-audit-management", element: <BaselineInternalAuditManagement /> },
    { path: "/re100-achievement", element: <RE100Achievement /> },
];
