import { MenuTemplate } from "@/components/Layout/MenuTemplate";
import { Flex, Menu, Text, Title } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

export const HeaderTemplate = () => {
    const userId = localStorage.getItem('userId');

    const navigate = useNavigate();
    return (
        <header>
            <Flex justify="space-between">
                <Flex align="center" gap="2rem">
                    <Title order={4}>
                        <Flex gap="xs" h="100%">
                            <Text c="cyan.4" size="2.5rem">SIZL</Text>
                            <Text size="2.5rem">EMS</Text>
                        </Flex>
                    </Title>
                    <MenuTemplate />
                </Flex>
                <Flex gap="xl" align="center">
                    {/* <Group spacing="xl" align="center">
                        <Group align="center" spacing="lg">
                            <ThemeIcon variant="outline" size="1.5rem" color="cyan">
                                <IconTemperature color="cyan" />
                            </ThemeIcon>
                            <Text>34°C</Text>
                        </Group>

                        <Group align="center" spacing="lg">
                            <ThemeIcon variant="outline" size="1.5rem" color="cyan">
                                <IconWind color="cyan" />
                            </ThemeIcon>
                            <Text>남</Text>
                        </Group>

                        <Group align="center" spacing="lg">
                            <ThemeIcon variant="outline" size="1.5rem" color="cyan">
                                <IconWind color="cyan" />
                            </ThemeIcon>
                            <Text>1m/s</Text>
                        </Group>

                        <Group align="center" spacing="lg">
                            <ThemeIcon variant="outline" size="1.5rem" color="cyan">
                                <IconDroplet color="cyan" />
                            </ThemeIcon>
                            <Text>40%</Text>
                        </Group>

                        <Group align="center" spacing="lg">
                            <ThemeIcon variant="outline" size="1.5rem" color="cyan">
                                <IconCloud color="cyan" />
                            </ThemeIcon>
                            <Text>구름</Text>
                        </Group>
                    </Group> */}

                    <Menu trigger="hover" shadow="md" offset={15}>
                        <Menu.Target>
                            <Flex align="center" style={{ cursor: 'pointer' }} gap="sm">
                                <Text size="1.5rem">{userId} 님</Text>
                                <IconChevronDown size="1rem" stroke={1.5} />
                            </Flex>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item component="a" onClick={() => { localStorage.removeItem("authToken"); navigate('/') }}>
                                <Text c="black" size="1rem">Logout</Text>
                            </Menu.Item>
                            {/* <Menu.Item component="a" onClick={() => { alert('Profile') }}>
                                <Text c="black" size="1rem">Profile</Text>
                            </Menu.Item>
                            <Menu.Item component="a" onClick={() => { alert('Settings') }}>
                                <Text c="black" size="1rem">Settings</Text>
                            </Menu.Item> */}
                        </Menu.Dropdown>
                    </Menu>
                </Flex>
            </Flex>
        </header>
    );
}