// import { useCheckAuth } from "@/utils/checkAuth";

import { Layout } from "@/components/Layout";
import { ProtectedRoute } from "@/router/ProtectedRoute";
import { Login, RoutePaths } from "@/router/RouteLocation";
import { checkAuth } from "@/utils/checkAuth";
import { Route, Routes, useLocation } from "react-router-dom";

export const Router = () => {
    const location = useLocation();
    // 로그인 여부 확인
    const auth = checkAuth();

    return (
        <Routes location={location}>
            <Route element={<Layout auth={auth} />}>
                <Route
                    path="/"
                    element={
                        <ProtectedRoute redirectCondition={auth} redirectPath="/energy-consumption-production">
                            {/* 임시로 에너지 소비 생산 현황 페이지로 이동 */}
                            <Login />
                        </ProtectedRoute>
                    }
                />
                {RoutePaths.map((route) => (
                    <Route
                        key={route.path}
                        path={route.path}
                        element={
                            <ProtectedRoute redirectCondition={!auth} redirectPath={"/"}>
                                {route.element}
                            </ProtectedRoute>
                        }
                    />
                ))}
            </Route>
        </Routes>
    );
}
