import { theme } from "@/styles/theme";
import { Global, css, useTheme } from "@emotion/react";
import { useEffect } from "react";

function GlobalStyle() {
  const theme = useTheme();

  useEffect(() => {
    const html = document.querySelector("html");
    html && (html.style.backgroundColor = "#0D3850");
  }, [theme]);

  const globalCSS = css`
  :root {
    --zIndex-0st: 11000;
    --zIndex-1st: 10000;
    --zIndex-2st: 9000;
    --zIndex-3st: 8000;

    --header-height: 2.5rem;
    --option-btn-right: 1rem;
  }

  @font-face {
    font-family: "NotoSansKR";
    src: url("/fonts/NotoSans-Regular.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    font-display: swap; /* 폰트가 로드되기 전까지 기본 폰트를 표시 */
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    color: white; /* 모든 텍스트를 흰색으로 지정 */
  }

  html, body {
  background-color: #0D3850; /* html과 body에 동일한 배경색 적용 */
    font-family: "NotoSansKR", sans-serif;
    color: white; /* 텍스트 색상을 흰색으로 명시 */
    height: 100vh;
  }

  body {
    font-size: 1.6rem;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    background-color: #0D3850; /* body 배경색 통일 */
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    color: white;
  }

  ul {
    list-style-type: none;
  }

  img {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  button {
    border: none;
    background-color: transparent;
    border-radius: 1rem;
    cursor: pointer;
    &:active,
    &:focus {
      outline: none;
    }
  }

  input {
    outline: none;
    &:focus::placeholder {
      color: transparent;
    }
  }

  .dx-submenu {
    background-color: #0D3850 !important;
  }

  .dx-datagrid-nodata {
    color: white;
  }

`;

  return <Global styles={[globalCSS]} />;
}

export const commonMantineInputStyle = css`
  &:focus-within .mantine-Input-input {
    border-color: ${theme.colors?.cyan?.[3]};  /* 포커스 시 테두리 색상 */
    border-width: 2px;   /* 테두리 굵기 */
  }
`;


export default GlobalStyle;
