import { Modal, Portal } from "@mantine/core";
import React, { createContext, useContext, useState } from "react";

type ModalProps = {
    component: React.ReactNode; // 모달로 사용될 컴포넌트
    parameter: any; // 전달할 파라미터
    opened: boolean; // 모달이 열려있는지 여부
    title: string; // 모달의 타이틀
    onClose: (result?: any) => void; // 모달이 닫힐 때 실행할 콜백 함수
    closeOnClickOutside?: boolean; // 모달 바깥을 클릭했을 때 모달을 닫을지 여부
};

type ModalContextType = {
    openModal: (
        component: React.ReactNode,
        parameter: any,
        title: string,
        closeOnClickOutside?: boolean
    ) => Promise<any>;
    closeModal: (result: any) => void;
};

const ModalContext = createContext<ModalContextType | undefined>(undefined);

const ModalStackManager: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [modalStack, setModalStack] = useState<ModalProps[]>([]);

    const openModal = (
        component: React.ReactNode,
        parameter: any,
        title: string,
        closeOnClickOutside?: boolean
    ): Promise<unknown> => {
        return new Promise<any>((resolve) => {
            const modalProps = {
                component,
                parameter,
                onClose: resolve,
                title,
                opened: true,
                closeOnClickOutside: closeOnClickOutside,
            };
            setModalStack((prevStack) => [...prevStack, modalProps]);
        });
    };

    const closeModal = (result?: any) => {
        setModalStack((prevStack) => {
            const currentModal = prevStack[prevStack.length - 1];
            currentModal.onClose(result);

            return prevStack.slice(0, -1);
        });
    };

    return (
        <ModalContext.Provider value={{ openModal, closeModal }}>
            {children}
            <Portal>
                {modalStack.map((modalProps, index) => (
                    <Modal
                        bg="dark"
                        opened={true}
                        onClose={() => closeModal(false)}
                        title={modalProps.title}
                        centered
                        size={"auto"}
                        key={index}
                        closeOnClickOutside={!modalProps.closeOnClickOutside} // true일 경우 닫히지 않고 false일 경우 닫힘
                        styles={() => ({
                            content: {
                                backgroundColor: "#0D3850"
                            },
                            header: {
                                backgroundColor: "#0D3850"
                            }
                        })}
                    >
                        {modalProps.component}
                    </Modal>
                ))}
            </Portal>
        </ModalContext.Provider>
    );
};

export const useModal = () => {
    const modalContext = useContext(ModalContext);
    if (!modalContext) {
        throw new Error("useModal must be used within a ModalStackManager");
    }
    return modalContext;
};

export default ModalStackManager;
