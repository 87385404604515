import 'devextreme/dist/css/dx.common.css';

import '@/dx-styles.scss';
import { Router } from '@/router';
import '@/themes/generated/theme.additional.css';
import '@/themes/generated/theme.base.css';
import { useScreenSizeClass } from '@/utils/media-query';
import { BrowserRouter } from 'react-router-dom';

export const App = () => {
  const screenSizeClass = useScreenSizeClass();

  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>

  );
}
