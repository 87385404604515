import { Link, links, LinkType, NestedLink } from "@/constants/MenuLinks";
import { Flex, Menu, Text } from "@mantine/core";
import { IconChevronDown, IconChevronRight } from "@tabler/icons-react";
import { Link as RouterLink } from "react-router-dom";

const RenderSubMenuItems = (items: (Link | NestedLink)[]) => {

    return items.map((item) => {
        if ('links' in item) {
            return (
                <Menu.Item component="div" key={item.label}>
                    <Flex justify="space-between" align="center">
                        <Menu shadow="md" trigger="hover" transitionProps={{ exitDuration: 0 }} position="right" offset={15}>
                            <Menu.Target>
                                <Flex justify="space-between" align="center" style={{ cursor: 'pointer', width: '100%' }} gap="sm">
                                    <Text color="black" size="1.2rem">{item.label}</Text> {/* 하위 메뉴는 검정색 */}
                                    <IconChevronRight size="1rem" stroke={1.5} color="black" />
                                </Flex>
                            </Menu.Target>
                            <Menu.Dropdown>
                                {RenderSubMenuItems(item.links)}
                            </Menu.Dropdown>
                        </Menu>
                    </Flex>
                </Menu.Item>
            );
        }
        return (
            <Menu.Item
                key={item.label}
                component={RouterLink}
                to={item.action ? "#" : item.link}
                color={item.action ? 'indigo' : ''}
            >
                <Text color="black" size="1.2rem">{item.label}</Text> {/* 하위 메뉴는 검정색 */}
            </Menu.Item>
        );
    });
};

const renderMainMenuItems = (items: LinkType) => {
    return items.map((item) => (
        <Menu key={item.label} shadow="md" trigger="hover" transitionProps={{ exitDuration: 0 }} offset={15}>
            <Menu.Target>
                {item.link ? (
                    <RouterLink to={item.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Flex align="center" style={{ cursor: 'pointer' }}>
                            <Text size="1.2rem">{item.label}</Text> {/* 최상단 메뉴는 흰색 */}
                        </Flex>
                    </RouterLink>
                ) : (
                    <Flex align="center" style={{ cursor: 'pointer' }} gap="sm">
                        <Text size="1.2rem">{item.label}</Text> {/* 최상단 메뉴는 흰색 */}
                        {item.links && <IconChevronDown size="1rem" stroke={1.5} />}
                    </Flex>
                )}
            </Menu.Target>
            {item.links && (
                <Menu.Dropdown>
                    {RenderSubMenuItems(item.links)}
                </Menu.Dropdown>
            )}
        </Menu>
    ));
};

export const MenuTemplate = () => {
    return (
        <Flex gap="1.5rem" h="100%" align="center">
            {renderMainMenuItems(links)}
        </Flex>
    );
};