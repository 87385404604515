import { HeaderTemplate } from "@/components/Layout/HeaderTemplate";
import { AppShell, Header } from "@mantine/core";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

export const Layout = ({ auth }: { auth: boolean }) => {
    return (
        <Main>
            {!auth ?
                <Outlet /> :
                <AppShell
                    header={
                        <Header height="6rem" p="xs" bg="#0D3850">
                            <HeaderTemplate />
                        </Header>}
                    bg="#0D3850"
                >
                    <Outlet />
                </AppShell>
            }
        </Main>
    );
};

const Main = styled.main`
  min-width: 100vw;
  min-height: 100vh;
  width: 100vw;
  height: 100vh;
`;
