import { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  redirectCondition?: boolean;
  redirectPath: string;
}

export const ProtectedRoute = ({
  children,
  redirectCondition,
  redirectPath,
}: PropsWithChildren<ProtectedRouteProps>) => {
  if (redirectCondition) {
    return <Navigate to={redirectPath} replace />;
  }

  return <>{children}</>;
}

