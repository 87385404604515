export type LinkType = {
    label: string;
    links?: (Link | NestedLink)[];
    link?: string;
}[];

export type Link = {
    link: string;
    label: string;
    action?: boolean;
};

export type NestedLink = {
    label: string;
    links: (Link | NestedLink)[];
    action?: boolean;
};

export const links: LinkType = [
    // { link: "/notice", label: "공지사항" },
    {
        label: "모니터링",
        links: [
            { link: "/energy-consumption-production", label: "에너지 소비 생산 현황" },
            { link: "/real-time-energy-cost", label: "실시간 에너지 소비 금액 현황" },
            // { link: "/carbon-reduction", label: "탄소 배출 저감" },
            // { link: "/kpi-indicators", label: "KPI 성과지표" },
            // { link: "/integrated-monitoring", label: "통합 모니터링" },
            // { link: "/main-equipment-monitoring", label: "주요 설비 모니터링" },
        ],
    },
    {
        label: "분석",
        links: [
            { link: "/daily-energy-consumption", label: "에너지 소비 현황 (일간)" },
            { link: "/monthly-energy-consumption", label: "에너지 소비 현황 (월간)" },
            // { link: "/yearly-energy-consumption", label: "에너지 소비 현황 (연간)" },
            // { link: "/daily-power-peak", label: "전력 소비 피크 현황 (일간)" },
            // { link: "/monthly-power-peak", label: "전력 소비 피크 현황 (월간)" },
            // { link: "/energy-consumption-production-report", label: "에너지 소비 생산 보고서" },
            // { link: "/monthly-billing", label: "월별 청구요금" },
        ],
    },
    // {
    //     label: "시뮬레이션",
    //     links: [
    //         { link: "/tariff-comparison", label: "요금제 비교" },
    //         { link: "/hourly-power-shift", label: "전력소비 쉬프트 (시간)" },
    //         { link: "/daily-solar-production", label: "태양광 생산 (일간)" },
    //         { link: "/ess-application", label: "ESS 적용" },
    //     ],
    // },
    // {
    //     label: "에너지경영",
    //     links: [
    //         {
    //             links: [
    //                 { link: "/energy-policy-management", label: "에너지 방침 관리" },
    //                 { link: "/energy-regulation-management", label: "에너지 법규 관리" },
    //                 { link: "/baseline-management", label: "BaseLine 관리" },
    //                 { link: "/energy-target-management", label: "에너지 목표 관리" },
    //                 { link: "/detailed-target-management", label: "세부목표 관리" },
    //                 { link: "/enpi-management", label: "EnPI[KPI] 관리" },
    //                 { link: "/energy-improvement-project-management", label: "에너지 개선 프로젝트 관리" },
    //             ],
    //             label: "에너지 기획"
    //         },
    //         {
    //             links: [
    //                 { link: "/energy-target-reduction-performance", label: "에너지 목표 절감성과 조회" },
    //                 { link: "/detailed-target-reduction-performance", label: "세부목표 절감성과 조회" },
    //                 { link: "/energy-project-performance", label: "에너지 프로젝트 성과 조회" },
    //             ],
    //             label: "실행 및 운영"
    //         },
    //         {
    //             links: [
    //                 { link: "/baseline-internal-audit-management", label: "BaseLine 내부심사 관리" },
    //             ],
    //             label: "점검 및 조치"
    //         },
    //         {
    //             links: [
    //                 { link: "/re100-achievement", label: "RE100 달성현황" },
    //             ],
    //             label: "RE100"
    //         }
    //     ],
    // }

];
